
import { defineComponent } from 'vue';
import VideoPlayer from '@/components/plyr/VideoPlayer.vue';
import GalleryDescription from '@/components/modals/Gallery/GalleryDescription.vue';
import UiOverlay from '../form/UiOverlay.vue';

export default defineComponent({
  title: 'Gallery',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    watermarks: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedItem: 0,
    model: false,
  }),
  computed: {
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    showNavigation(): boolean {
      return this.items.length > 1;
    },
  },
  components: {
    VideoPlayer,
    UiOverlay,
    GalleryDescription,
  },
  watch: {
    model(val) {
      if (!val) {
        this.selectedItem = 0;
      }
    },
    selectedItem() {
      (this.$refs.overlay as any).scrollTop();
    },
  },
  methods: {
    open(index: any) {
      this.selectedItem = index;
      this.model = true;
    },
    slide(direction: string) {
      const slides = this.items.length - 1;

      if (direction === 'right') {
        this.selectedItem = this.selectedItem < slides ? this.selectedItem + 1 : slides;
      } else {
        this.selectedItem = this.selectedItem === 0 ? 0 : this.selectedItem - 1;
      }
    },
    imageSrc(id: string, preset?: string) {
      return this.watermarks
        ? `${this.baseApiUrl}/api/media/${id}/preset/${preset}`
        : `${this.baseApiUrl}/api/media/show/${id}`;
    },
  },
});
