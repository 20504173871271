
import { defineComponent } from 'vue';
import VueMeta from '@/components/meta/VueMeta.vue';
import InventoryService from '@/services/InventoryService';
import VideoPlayer from '@/components/plyr/VideoPlayer.vue';
import Gallery from '@/components/modals/Gallery.vue';
import UiCombobox from '@/components/form/UiCombobox.vue';
import UiSearch from '@/components/form/UiSearch.vue';
import Pagination from '@/components/pagination/Pagination.vue';
import PreLoader from '@/components/PreLoader/PreLoader.vue';
import RolePermissions from '@/mixins/RolePermissions';

export default defineComponent({
  name: 'CompanyShow',
  mixins: [RolePermissions],
  data: () => ({
    isLoaded: false,
    isProcessing: false,
    company: {} as any,
    drugs: [],
    drugsTotal: 0,
    drugPage: 1,
    drugPages: 0,
    searchTimeoutId: 0,
    drugSearchInput: '',
    topic: {} as any,
    topicSearchInput: '',
  }),
  computed: {
    id(): any {
      return this.$route.params.id;
    },
    baseApiUrl() {
      return process.env.VUE_APP_BASE_API_URL;
    },
    topics(): any {
      return [
        ...new Set(this.company.details.contacts.map((el: any) => el.topic)),
      ].map((el: any) => ({ topic: el }));
    },
    filteredTopics(): any {
      return this.topics?.filter((el: any) => el.topic?.toUpperCase()
        ?.includes(this.topicSearchInput?.toUpperCase()));
    },
    contactsByTopic(): any {
      return this.company.details.contacts.filter(
        (el: any) => el.topic === this.topic.topic,
      );
    },
    currentItems(): number {
      const currentTotal = 10 * this.drugPage;
      return currentTotal >= this.drugsTotal ? this.drugsTotal : currentTotal;
    },
    metaTitle(): any {
      return `${this.company.title} - ${process.env.VUE_APP_TITLE}`;
    },
    metaDescription(): any {
      let desc = this.company.description;

      desc = desc.replace(/<[^>]+>/g, '');
      desc = desc.replace(/(\r\n|\n|\r)/gm, ' ');

      return desc || '';
    },
    metaHref(): any {
      return `${window.location.origin}/company/${this.company.slug}`;
    },
    metaInfo() {
      return {
        title: this.metaTitle,
        description: this.metaDescription,
        link: [{ rel: 'canonical', href: this.metaHref }],
      };
    },
  },
  components: {
    VueMeta,
    Gallery,
    VideoPlayer,
    UiCombobox,
    UiSearch,
    Pagination,
    PreLoader,
  },
  watch: {
    async drugSearchInput(val) {
      try {
        this.isProcessing = true;

        this.drugPage = 1;

        window.clearTimeout(this.searchTimeoutId);

        this.searchTimeoutId = window.setTimeout(async () => {
          const params = {
            title: val,
            producer_id: this.company.id,
            published: true,
            order_by: 'title:asc,code:asc',
            take: 10,
          };

          const drugs = await InventoryService.drugSearch(params);

          this.drugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));
          this.drugsTotal = drugs.total;
          this.drugPages = drugs.last_page;

          this.isProcessing = false;
        }, 300);
      } catch (error) {
        if (error) this.isProcessing = false;
      }
    },
  },
  methods: {
    transformCompanyForEdit(model: any) {
      return {
        code: model.code,
        description: model.description,
        details: {
          address: {
            country: model.details.address.country,
            region_postcode: model.details.address.region_postcode,
            city: model.details.address.city,
            street_appartment: model.details.address.street_appartment,
          },
          company_contact: {
            cvrnr: model.details.company_contact.cvrnr,
            email: model.details.company_contact.email,
            telephone: model.details.company_contact.telephone,
            website: model.details.company_contact.website,
          },
          contacts: model.details.contacts?.length
            ? model.details.contacts.map((el: any) => ({
              topic: el.topic,
              direct: el.direct,
              email: el.email,
              full_name: el.full_name,
              mobile: el.mobile,
            }))
            : [],
          images: model.details.images.map((el: any) => ({
            default: el.default,
            description: el.description,
            link: el.link,
            path: el.path,
          })),
        },
        id: model.id,
        short_code: model.short_code,
        slug: model.slug,
        title: model.title,
      };
    },
    validateUrl(value: string) {
      return /(http(s?)):\/\//i.test(value) ? value : `https://${value}`;
    },
    async searchDrugs() {
      const params = {
        title: this.drugSearchInput,
        producer_id: this.company.id,
        published: true,
        order_by: 'title:asc',
        page: this.drugPage,
        take: 10,
      };

      const drugs = await InventoryService.drugSearch(params);

      this.drugs = drugs.data.map((el: any) => this.transformDrugForEdit(el));
      this.drugsTotal = drugs.total;
      this.drugPages = drugs.last_page;

      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    },
    transformDrugForEdit(model: any) {
      return {
        id: model.id,
        slug: model.slug,
        title: model.title,
        dosage_form_text: model.details.dosage_form.text,
        strength_text: model.details.strength.text ? `(${model.details.strength.text})` : '',
        description: model.entity_categories
          ?.filter((el: any) => el.category_type_code === 'activeingredient')
          ?.map((el: any) => el.category_title)
          ?.join(', '),
        promoted: model.promoted,
      };
    },
    editCompany() {
      this.$router.push({
        name: 'CompanyEdit',
        params: { id: this.id },
      });
    },
    openGallery(index: any) {
      (this.$refs.gallery as any).open(index);
    },
  },
  async mounted() {
    if (!this.id) {
      this.isLoaded = true;
      return;
    }

    try {
      const company = await InventoryService.producer(this.id);

      this.company = this.transformCompanyForEdit(company);

      await this.searchDrugs();

      this.isLoaded = true;
    } catch (error: any) {
      if (error?.response?.status === 404) {
        this.$router.push({ name: '404' });
        return;
      }

      if (error?.response?.status === 422 || error?.response?.status === 409) {
        this.$toast.show(error.response.data?.error?.message, 3000);
        return;
      }

      this.$toast.show('Server error', 3000);
    }
  },
});
