import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-3fbb72e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "read-more" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["description", { open: _ctx.open, 'no-select': _ctx.noSelect }])
    }, [
      _createElementVNode("div", {
        innerHTML: _ctx.formatText(_ctx.text)
      }, null, 8, _hoisted_1)
    ], 2),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.open = true))
      }, "Læs mere", 512), [
        [_vShow, _ctx.readMore && !_ctx.open]
      ])
    ])
  ], 64))
}