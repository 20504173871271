
import { defineComponent } from 'vue';

export default defineComponent({
  title: 'Gallery',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    open: false,
    readMore: false,
    noSelect: false,
  }),
  watch: {
    text: {
      immediate: true,
      handler() {
        this.open = false;
        this.noSelect = true;

        setTimeout(() => {
          this.noSelect = false;
        }, 500);

        this.$nextTick(() => {
          const el = document.querySelector('.description') as HTMLElement;
          this.readMore = el?.scrollHeight > 66;
        });
      },
    },
  },
  methods: {
    formatText(val?: string) {
      return val?.replace(/\n/g, '<br />');
    },
  },
});
